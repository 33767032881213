<template>
  <div>
    <div class="">
      <v-card :loading="loading" :disabled="loading" loader-height="2">
        <v-card-text>
          <div class="d-flex align-center">
            <v-btn small color="primary" @click="addprojectdialog = true">
              <v-icon left>mdi-plus</v-icon>
              <span>Add New Project</span>
            </v-btn>
            <v-btn icon small class="mr-2" @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
            <v-spacer></v-spacer>
          </div>
        </v-card-text>
        <v-card-text>
          <v-data-table :headers="headers" dense :search="search" :items="getData" :items-per-page="-1"
            hide-default-footer>
            <template #[`item.from`]="{ value }">
              {{ $nova.formatDate(value) }}
            </template>
            <template #[`item.to`]="{ value }">
              {{ $nova.formatDate(value) }}
            </template>
            <template v-slot:[`item.stage`]="{ value }">

              <v-chip small label color="info" v-if="value === 0">Open</v-chip>
              <v-chip small label text color="success" class="" v-else-if="value === 2">Closed</v-chip>
              <v-chip small label text color="error" v-else-if="value === 1">Freezed</v-chip>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on" @click="editProject(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="[1, 2].indexOf(item.stage) > -1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="" v-bind="attrs" v-on="on" @click="openProject(item._id)">
                    <v-icon>mdi-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>Open</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="[1, 0].indexOf(item.stage) > -1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="" v-bind="attrs" v-on="on" @click="closeProject(item._id)">
                    <v-icon>mdi-circle</v-icon>
                  </v-btn>
                </template>
                <span>Close</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="item.stage === 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="" v-bind="attrs" v-on="on" @click="freezeProject(item._id)">
                    <v-icon>mdi-circle-off-outline</v-icon>
                  </v-btn>
                </template>
                <span>Freeze</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="" v-bind="attrs" v-on="on"
                    @click="$nova.gotoLink({ path: '/flux/analysis/' + item._id })">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>
              <lb-activitylog :config="activitylogconfig" :url="`/v2/fluxmanagement/project/getlogs/${item._id}`" />
              <!-- <v-menu offset-y transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="view-on-hover-item-d mx-0 px-0"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>mdi-menu</v-icon>
                  </v-btn>
                </template>
                <v-list dense class="pa-0 border-left-default">
                  <v-list-item @click="editProject(item)">
                    <v-list-item-title
                      ><v-icon left>mdi-pencil</v-icon>Edit</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    @click="closeProject(item._id)"
                    v-if="item.stage === 0"
                  >
                    <v-list-item-title
                      ><v-icon left>mdi-close-circle</v-icon
                      >Close</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="openProject(item._id)" v-else>
                    <v-list-item-title
                      ><v-icon left>mdi-lock-open-check-outline</v-icon
                      >Open</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="freezeProject(item._id)">
                    <v-list-item-title
                      ><v-icon left>mdi-shield-refresh</v-icon
                      >Freeze</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu> -->
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>

    <v-dialog v-model="addprojectdialog" max-width="600" persistent>
      <v-card :loading="addprojectloading" :disabled="addprojectloading" loader-height="2" class="">
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text" v-if="addeditproject._id">Update Projects</span>
          <span class="subtitle-1 white--text" v-else>Create Projects</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="addprojectdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-text>
        <v-card-text class="mt-4 pt-4">
          <v-row>
            <v-col cols="4" class="my-0 py-1">
              <lb-date v-model="addeditproject.from" label="Period From" :error="addeditprojecterror.from"
                :max="addeditproject.to || $nova.formatDateISO(new Date()) || 0" />
            </v-col>
            <v-col cols="4" class="my-0 py-1">
              <lb-date v-model="addeditproject.to" label="Period To" :error="addeditprojecterror.to"
                :min="addeditproject.from" :max="$nova.formatDateISO(new Date())" />
            </v-col>
            <v-col cols="4" class="my-0 py-1">
              <lb-date v-model="addeditproject.duedate" label="Due Date" :min="$nova.formatDateISO(new Date())"/>
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-string v-model="addeditproject.description" label="Description"
                :error="addeditprojecterror.description" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn @click="updateProject()" v-if="addeditproject._id" color="primary">Update</v-btn>
          <v-btn @click="addProject()" v-else color="primary">Save</v-btn>
        </v-card-text>
        <v-card-text> </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      addprojectdialog: false,
      addeditproject: {},
      addeditprojecterror: {},
      addprojectloading: false,
      userdata: [],
      formdata: [],
      getData: [],
      search: "",
      headers: [
        { text: "Description", value: "description" },
        { text: "From", value: "from" },
        { text: "To", value: "to" },
        { text: "Status", value: "stage" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      activitylogconfig: {
        userassociate: { icon: "mdi-plus", color: "info", displaytext: "User Associated" },
        userreplaced: { icon: "mdi-account-convert", color: "info", displaytext: "User Replaced" },
        userremoved: { icon: "mdi-close", color: "error", displaytext: "User Removed" },
        usermoved: { icon: "mdi-sync", color: "", displaytext: "Updated" },
        projectcreate: { icon: "mdi-plus", color: "", displaytext: "Updated" },
        projectopen: { icon: "mdi-circle-outline", color: "", displaytext: "Updated" },
        projectfreeze: { icon: "mdi-circle-off-outline", color: "", displaytext: "Updated" },
        projectclose: { icon: "mdi-circle", color: "", displaytext: "Updated" },
        projectupdate: { icon: "mdi-pencil", color: "", displaytext: "Updated" },
        dataadded: { icon: "mdi-upload", color: "", displaytext: "Updated" },
      },
    };
  },
  created() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.loading = true;

      this.axios.post("/v2/financialclose/project/getformdata/").then((dt) => {
        if (dt.data.status === "success") {
          this.formdata = dt.data.data[0] || {};
          this.userdata = this.formdata.users;
          return this.axios.post("/v2/fluxmanagement/project/get");
        } else throw Error("Error loading projects");
      }).then((ele) => {
        if (ele.data.status === "success") {
          this.getData = ele.data.data || [];
        }else throw Error(ele.message || "Unknown error");
      }).catch((err) => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    addProject() {
      // console.log(this.addeditproject);
      this.loading = true;
      let success = false;
      this.axios
        .post("/v2/fluxmanagement/project/add", { data: this.addeditproject })
        .then((ele) => {
          console.log(ele);
          if (ele.data.status === "success") {
            this.addprojectdialog = false;
            success = true;
            this.$store.commit("sbSuccess", "Project Added");
            if (ele.data.data.length > 0) {
              this.$nova.gotoLink({ path: '/flux/analysis/' + ele.data.data[0]._id })
            } else this.refreshData();
          }else{
            if (ele.data.data.length > 0){
              if(ele.data.data[0].index0) this.addeditprojecterror = ele.data.data[0].index0;
            }else throw new Error (ele.data.message);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          if(success) this.refreshData();
        });
    },
    editProject(item) {
      this.addprojectdialog = true;
      this.addeditproject = { ...item };
    },
    updateProject() {
      this.loading = true;
      let success = false;
      this.axios
        .post("/v2/fluxmanagement/project/edit/" + this.addeditproject._id, {
          data: this.addeditproject,
        })
        .then((ele) => {
          if (ele.data.status === "success") {
            this.addprojectdialog = false;
            success = true;
            this.$store.commit("sbSuccess", "Project Updated");
          } else {
            throw new Error("Error updating Bots");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          if(success) this.refreshData();
        });
    },
    openProject(id) {
      this.loading = true;
      let success = false;
      this.axios
        .post("/v2/fluxmanagement/project/open/" + id)
        .then((ele) => {
          if (ele.data.status === "success") {
            success = true;
            this.$store.commit("sbSuccess", "Project Re-Open");
          } else {
            throw new Error("Error open project");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          if(success) this.refreshData();
        });
    },
    closeProject(id) {
      this.loading = true;
      let success = false;
      this.axios
        .post("/v2/fluxmanagement/project/close/" + id)
        .then((ele) => {
          if (ele.data.status === "success") {
            success = true;
            this.$store.commit("sbSuccess", "Project Closed");
          } else {
            throw new Error("Error close project");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          if(success) this.refreshData();
        });
    },
    freezeProject(id) {
      this.loading = true;
      let success = false;
      this.axios
        .post("/v2/fluxmanagement/project/freeze/" + id)
        .then((ele) => {
          if (ele.data.status === "success") {
            success = true;
            this.$store.commit("sbSuccess", "Project Freezed");
          } else {
            throw new Error("Error freeze project");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          if(success) this.refreshData();
        });
    },
  },
};
</script>

<style></style>